import React, { useState, useEffect } from 'react'
import { MdArrowRight } from "react-icons/md";
import { MdArrowDropDown } from "react-icons/md";
import { HashLink as Link } from 'react-router-hash-link';
import { FaArrowTrendUp } from "react-icons/fa6";
import Modal from "../components/Modal"
import RSECalendar from '../components/RSECalendar';

export default function HomePage({setMobileNavActive}) {


  const [detailItem, setDetailItem] = useState([])
  const [modal, setModal] = useState()
  const [fullImgSrc, setFullImgSrc] = useState()
  const [scrollYPosition, setScrollYPosition] = React.useState(0);

  const handleScroll = () => {
    const newScrollYPosition = window.pageYOffset;
    setScrollYPosition(newScrollYPosition);
   };

   useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);


  const handleDetailedList= async (e) =>  {
    if(detailItem !== e.target.id) {
      setDetailItem(e.target.id)
    } else {
      setDetailItem()
    }
  }

  const handleFullImg = async (e) => {
    setModal(true)
    setFullImgSrc(e.target.src)
    // window.scrollTo(0, 9400)
  }


  

  return (
    <>
     {modal && 
      <Modal setModal={setModal} fullImgSrc={fullImgSrc} position={scrollYPosition}/>   
    }

    <div className="homePage" onClick={() => setMobileNavActive(false)}>
      <div  className="whatIsIt">
      <div style={{marginTop: "-100px"}} id="action_Rse" className="anchor"></div>
        <div className="logos">
          <div className="imgBox">
            <a href="https://www.ideis-asso.fr/">
              <img src="https://action-rse.fr//Assets/IDEIS_logo-01-removebg-preview.png" alt="" />
            </a>
          </div>
          <div className="imgBox">
            <a href="https://mife90.org/">
              <img src="https://action-rse.fr/Assets/logo-MIFE90-TRANSPARENT.png" alt="" />
            </a>
          </div>
          <div className="imgBox">
              <img src="https://action-rse.fr/Assets/action_rse_logo.png" alt="" />
          </div>
        </div>


        <div className="anchor" id="le_projet_Action_RSE_NFC"></div>
        <div className="paraph_3">
            <h3>Le projet Action RSE Nord Franche-Comté</h3>

            <img style={{width: "210px", margin: "20px auto 40px", display: "block"}} src="https://action-rse.fr/Assets/cofinance_par_ue.png" alt="" />

            <p>L’engagement social, environnemental, économique et territorial d’une entreprise est aujourd’hui un gage de reconnaissance et donc d’attractivité. Les entreprises en sont de plus en plus conscientes et souhaitent développer ou renforcer leurs actions RSE.<br /><br />

            L’équipe <strong>Action RSE NFC</strong> met en œuvre un projet global de promotion de la RSE auprès des entreprises en participant à la diffusion des informations et en facilitant les contacts avec les acteurs régionaux spécialistes du sujet.<br /><br />

            Action RSE Nord Franche-Comté, dans le Pays de Montbéliard et le Territoire de Belfort, est piloté par <strong>IDEIS</strong> en partenariat avec la <strong>MIFE.</strong> Il répond aux enjeux suivants :</p><br /><br /> 

            <ul style={{marginLeft: "150px"}}>
                <li><strong>Faciliter la fidélisation et les recrutements des entreprises locales</strong></li>
                <li><strong>Renforcer les démarches d’inclusion des entreprises en direction des publics éloignés de l’emploi</strong></li>
                <li><strong>Développer la culture du mécénat social et de compétences</strong></li>
            </ul>
            
            
           
        </div>
      <div className="anchor" id="qu_est_ce_que_la_rse"></div>
      <div className="anchor" id="definition"></div>
        <h1>Qu’est-ce que la RSE ?</h1>
        <div className="whiteFrame">
            <div className="quote">
            La Responsabilité Sociétale des Entreprises désigne « l’intégration volontaire, 
            par les entreprises, de préoccupations sociales et environnementales à leurs activités 
            commerciales et leurs relations avec leurs parties prenantes ».
            </div>
            <div className="author">
            Définition de la Commission européenne
            </div>
         </div>
        </div>
     
        <div className='paraph_1'>
        <p><strong>Le Ministère de l’Écologie précise que :</strong><br/>

        La responsabilité d’une organisation vis-à-vis des impacts de ses décisions et de ses activités sur la société et sur l’environnement, se traduit par un comportement transparent et éthique qui :
        </p>
        <ul>
            <li>Contribue au développement durable, y compris à la santé et au bien-être de la société.</li>
            <li>Prend en compte les attentes des parties prenantes.</li>
            <li>Est intégré dans l’ensemble de l’organisation et mis en œuvre dans ses relations. »</li>
        </ul>
      
        <a target="blank" href="https://www.ecologie.gouv.fr/politiques-publiques/responsabilite-societale-entreprises">https://www.ecologie.gouv.fr/politiques-publiques/responsabilite-societale-entreprises</a>
    
        </div>
        <div className="anchor" id="les_3_piliers_de_la_RSE"></div>
        <div className='paraph_2'>
          <div className="asterisk">✳</div>
          <h2>La RSE s’appuie sur 3 piliers</h2>
          <p className='subTitle' style={{width: "80%", margin:"0 auto"}}>à prendre en compte pour préserver la planète et l’avenir des générations futures. Le développement d’une entreprise engagée dans une démarche de RSE doit se faire de manière équilibrée dans ces trois domaines pour être responsable et durable.</p>

          <div className="pillars">
            <div className="pillar">
                <img src="https://action-rse.fr/Assets/pilier-removebg-preview.png" alt="" />
                <h4>Économique</h4>
                <ul>
                    <li><span>•</span> Satisfaire et fidéliser les clients</li>
                    <li><span>•</span> Assurer la qualité de service</li>
                    <li><span>•</span> Soutenir les fournisseurs locaux</li>
                    <li><span>•</span> Régler les factures dans les délais</li>
                    <li><span>•</span> Faire preuve de transparence auprès des investisseurs</li>
                </ul>
            </div>
            <div className="pillar">
                <img src="https://action-rse.fr/Assets/pilier-removebg-preview.png" alt="" />
                <h4>Social</h4>
                <ul>
                    <li><span>•</span> Renforcer l’égalité des chances et la diversité</li>
                    <li><span>•</span> Garantir l’hygiène et la sécurité sur le lieu de travail</li>
                    <li><span>•</span> Faire respecter pleinement le droit du travail</li>
                    <li><span>•</span> Former le personnel</li>
                </ul>
            </div>
            <div className="pillar">
                <img src="https://action-rse.fr/Assets/pilier-removebg-preview.png" alt="" />
                <h4>Environnemental</h4>
                <ul>
                    <li><span>•</span> Réduire les émissions de gaz à effet de serre (GES)</li>
                    <li><span>•</span> Utiliser les ressources naturelles de manière durable</li>
                    <li><span>•</span> Limiter les déchets, notamment ceux qui sont dangereux</li>
                    <li><span>•</span> Favoriser la diversité biologique</li>
                </ul>
            </div>
          </div>

          <div className="anchor" id="la_RSE_pour_quelle_plus_value"></div>

        <h3>Pour quelle plus-value ?</h3>
        <div className="grid">
          <div>
            <p>
              <strong style={{fontSize: "20px", fontWeight: "700", fontStyle: "italic"}}>Accroitre sa performance :</strong> plusieurs études ont montré la corrélation entre les démarches RSE mises en place par les entreprises et leurs performances économiques. Par exemple, l’étude de 2016 de France Stratégie<br /><br />
              Cette amélioration de performance s’accompagne d’une <strong style={{fontSize: "16.8px", fontWeight: "700", fontStyle: "italic"}}>réduction évidente des coûts :</strong>
            </p>
            <br />
            <p className="subText">
              Réduire ses émissions de gaz à effets de serre (GES) c’est <strong style={{  fontSize: "16.8px", fontWeight: "700", fontStyle: "italic"}}>réduire les coûts de fonctionnement et améliorer la qualité des produits.</strong>
            </p>
            <p className="subText">
              Réduire les déchets et favoriser le recyclage permet de <strong style={{  fontSize: "16.8px", fontWeight: "700", fontStyle: "italic"}}>limiter les pertes.</strong>
            </p>
            <br />
            <p>
              Favoriser le bien-être au travail permet <strong style={{  fontSize: "16.8px", fontWeight: "700", fontStyle: "italic"}}>d’améliorer la productivité et l’efficacité des équipes</strong> et par conséquence les performances opérationnelles.<br /><br />
              S’engager socialement et adopter des pratiques respectueuses de l’environnement permet de <strong style={{  fontSize: "16.8px", fontWeight: "700", fontStyle: "italic"}}>valoriser l’image de marque de l’entreprise</strong> auprès des parties prenantes (clients, fournisseurs, prestataires, partenaires commerciaux…) et ainsi <strong style={{  fontSize: "16.8px", fontWeight: "700", fontStyle: "italic"}}>d’améliorer les performances commerciales.</strong><br /><br />
              La RSE permet également d’améliorer la marque employeur, <strong style={{  fontSize: "16.8px", fontWeight: "700", fontStyle: "italic"}}>d’attirer les talents, de renforcer l’engagement et l’adhésion des collaborateurs</strong> et de réduire le turn-over et le taux d’absentéisme.<br /><br />
              Pour aller plus loin : <a target="blank" href='https://www.novethic.fr/entreprises-responsables/la-rse-dans-lentreprise.html'>https://www.novethic.fr/entreprises-responsables/la-rse-dans-lentreprise.html</a>
            </p>
          </div>
            <a target="blank" href="https://action-rse.fr/Assets/fs_etude_rse_finale (1).pdf"><img src="https://action-rse.fr/Assets/Responsabilité sociale des entreprises et compétitivité_page-0001.jpg" alt="" /></a>
        </div>

   

    

        <div id="ameliorer_mes_pratiques_RSE" className="anchor"></div>
        <div className="grid_2">
          <h3>Je veux améliorer mes pratiques</h3>

          <div className="needs">
            <h4>J'ai besoin de...</h4>

            <ul>
              <li>Être informé, sensibilisé aux principes de la RSE</li>
              <li>Être accompagné individuellement ou collectivement dans mon entreprise</li>
              <li>Financer un projet interne</li>
              <li>Valoriser mes actions RSE</li>
            </ul>
          </div>

          <div className="practices">
            <h4>J'agis sur...</h4>
            <dl>
              <dt id="1" onClick={e => handleDetailedList(e)}><div className="icon">{detailItem === "1" ? <MdArrowDropDown /> : <MdArrowRight />}</div> La gouvernance de l’organisation.</dt>
              <dd className={detailItem === "1" ? "show" : "hide"}>Système par lequel une organisation prend des décisions et les applique en vue d’atteindre ses objectifs</dd>
              <dt id="2" onClick={e => handleDetailedList(e)}><div className="icon">{detailItem === "2" ? <MdArrowDropDown /> : <MdArrowRight />}</div> Les droits de l’homme.</dt>
              <dd className={detailItem === "2" ? "show" : "hide"}>Respect de l’individu, de sa dignité et de ses droits fondamentaux individuels, collectifs et sociaux. Lutte contre la discrimination et promotion de l’égalité des chances. Protection des données personnelles et /ou confidentielles des parties prenantes</dd>
              <dt id="3" onClick={e => handleDetailedList(e)}><div className="icon">{detailItem === "3" ? <MdArrowDropDown /> : <MdArrowRight />}</div> Les relations et conditions de travail.</dt>
              <dd className={detailItem === "3" ? "show" : "hide"}>Pérennité et progression des compétences des collaborateurs. Intégration des collaborateurs par la communication. Protection de leur santé et de leur sécurité. Juste rémunération des collaborateurs, « équitable et transparente »</dd>
              <dt id="4" onClick={e => handleDetailedList(e)}><div className="icon">{detailItem === "4" ? <MdArrowDropDown /> : <MdArrowRight />}</div> L’environnement.</dt>
              <dd className={detailItem === "4" ? "show" : "hide"}>Respect des lois environnementales. Gestion des déchets. Gestion de la pollution atmosphérique. Gestion et pollution de l’eau. Norme ISO 14001 ou 50001. Mise en place d’un Plan Mobilité. Réduction et recyclage des déchets. Gestion responsable des ressources, eau et énergie. Calcul de l’empreinte environnementale.</dd>
              <dt id="5" onClick={e => handleDetailedList(e)}><div className="icon">{detailItem === '5' ? <MdArrowDropDown /> : <MdArrowRight />}</div> La loyauté des pratiques.</dt>
              <dd className={detailItem === '5' ? "show" : "hide"}>Lutte contre la corruption. Engagement politique responsable. Concurrence loyale. Promotion de la responsabilité sociétale dans la chaîne de valeur. Respect des droits de propriété</dd>
              <dt id="6" onClick={e => handleDetailedList(e)}><div className="icon">{detailItem === "6" ? <MdArrowDropDown /> : <MdArrowRight />}</div> Les questions relatives aux consommateurs.</dt>
              <dd className={detailItem === "6" ? "show" : "hide"}>Communication d’informations fiables. Protection de la santé et de la sécurité des consommateurs. Consommation durable. Résolution des conflits. Protection des données et vie privée. Accès aux produits et services pour les populations les plus fragiles.</dd>
              <dt id="7" onClick={e => handleDetailedList(e)}><div className="icon">{detailItem === "7" ? <MdArrowDropDown /> : <MdArrowRight />}</div> Les communautés et le développement local. </dt>
              <dd className={detailItem === "7" ? "show" : "hide"}>Contribution au bien-être des communautés. Renforcement des partenariats locaux. Création de valeurs partagées</dd>
            </dl>

            <p>Ces 7 thèmes constituent les questions centrales de la norme AFNOR ISO 26000 :</p>
            <p>Pour en savoir plus : <a target="blank" href="https://www.afnor.org/developpement-durable/demarche-iso-26000">https://www.afnor.org/developpement-durable/demarche-iso-26000</a></p>
          </div>

        </div>

       
        <div className="ideas">
          <div className="anchor" id="des_idees_pour_demarrer"></div>
          <h3 style={{marginTop: "200px", marginBottom: "100px"}}>Des actions locales pour améliorer mes pratiques</h3>
          <div className="grids">

          <div className="left">

            <div className="row"><div className="dot"><p>La Dotation d’Action Territoriale</p> <p>Contact : Maud PETIT | IDEIS</p> <a href="mailto:maud.petit@ideis-asso.fr" className='mail'>maud.petit@ideis-asso.fr</a> <a href="tel:0628464301" className='tel'>06 28 46 43 01</a></div><a className='img' target="blank" href="https://www.dat-france.org/" ><img src="https://action-rse.fr/Assets/logo_DAT_Break_Poverty.png" alt="" /></a></div>
            
              <div className="row" id="lesEntreprisesSengagent"> 
               <div className="les90"><p>Le club les entreprises s’engagent 90</p> <p>Contact : Julie WERMELINGER | CREPI Alsace Franche-Comté</p> <a href="mailto:julie.wermelinger@crepi.org" className='mail'>julie.wermelinger@crepi.org</a> <a href="tel:0766178710" className='tel'>07 66 17 87 10</a></div>
               <div className="les25"><p>Le club les entreprises s’engagent 25</p> <p>Contact : Frédérique GENTNER-MARMIER | club FACE Doubs</p> <a href="mailto:f.gentner@fondationface.org"  className='mail'>f.gentner@fondationface.org</a> <a href="tel:0676928830" className='tel'>06 76 92 88 30</a></div>
               <div className="imgs">
                <a className="img" target="blank" href="https://lesentreprises-sengagent.gouv.fr/clubs/90">
                  <img src="https://action-rse.fr/Assets/d3JS35CaQ9e29yp9exD0.webp" alt="" />
                </a>
                <a className="img" target="blank" href="https://lesentreprises-sengagent.gouv.fr/clubs/25">
                  <img src="https://action-rse.fr/Assets/BXiWzbP5QReiAc3Ew8eg.webp" alt="" />
                </a>
                </div>
              </div>
             </div>
         
            <div className="right">
              <div className="row"><div className="ideis"><p>La charte de Responsabilité Sociale Territoriale</p> <p>Contact : IDEIS Entreprises & Territoire</p> <a href="tel:0381710415" className='tel'>03 81 71 04 15</a></div><a className="img" href="https://www.ideis-asso.fr/projets-ideis" target="blank"><img src="https://action-rse.fr/Assets/IDEIS_logo-01-removebg-preview.png" alt="" /></a></div>
              <div className="row"><div className="emp"><p>Le label Empl’itude</p> <p>Contact : Charlotte BECK | MIFE</p> <a href="mailto:charlotte.beck@mife90.org"  className='mail'>charlotte.beck@mife90.org</a> <a href="tel:0750554527" className='tel'>07 50 55 45 27</a></div> <a className="img" target="blank" href="https://mife90.org/emplitude/form/emplitude_form/"><img className="emplitudeLogo" src="https://action-rse.fr/Assets/logo-EMPLITUDE-TdB-transparent.png" alt="" /></a></div>
            </div>
          </div>
          <div className="anchor" id="mon_bilan_carbone"></div> 
          
          <h4>Un bilan carbone pour mesurer mon impact environnemental</h4>
          <p style={{maxWidth: "76%", margin: "-20px auto", textAlign: "center"}} className="baseline">Une comptabilité carbone précise permet aux entreprises de savoir où elles émettent et où concentrer leurs efforts de réduction.</p>
          <p className="bilanCarbone">
          <FaArrowTrendUp /> Réduire les coûts et gagner en compétitivité, le bilan carbone est la première étape d’une stratégie plus large d’amélioration des performances opérationnelles de votre entreprise.<br/><br/>

          <FaArrowTrendUp /> Limiter les risques croissants du changement climatique et ses impacts physiques pour l’entreprise (inondations, sécheresses, tempêtes…)<br/><br/>

          <FaArrowTrendUp /> Répondre aux aspirations des collaborateurs en entretenant un cercle vertueux permettant de renforcer leur motivation et leur adhésion au projet de l’entreprise<br/><br/>

          <FaArrowTrendUp /> Booster les parts de marché et l’image de l’entreprise ; les informations « carbone » sont intégrées aux appels d’offre et deviennent un réel critère de décision dans le choix des fournisseurs.<br/><br/> 

          <FaArrowTrendUp /> Respecter et anticiper la réglementation européenne qui s’applique déjà aux PME, ETI et grands groupes : <a target="blank" href="https://entreprendre.service-public.fr/actualites/A16970">https://entreprendre.service-public.fr/actualites/A16970</a><br/><br/>

          <strong>Pour me lancer :</strong><br/><br/>

          <a target="blank" href="https://bigmedia.bpifrance.fr/nos-dossiers/bilan-carboner-de-lentreprise-definition-obligations-etapes-de-calcul-0">Bilan Carbone® de l’entreprise : définition, obligations, étapes de calcul | Big média | S’inspirer, S’informer, S’engager (bpifrance.fr)</a><br/><br/>
          <a target="blank" href="https://www.economie.gouv.fr/cedef/bilan-carbone-entreprise">Comment établir le bilan carbone d’une entreprise ? | economie.gouv.fr</a>

          </p>
        </div>
        <div className="anchor" id="les_experts_regionaux_de_la_RSE"></div>
        <div className="expertsBFC">
          <h3 style={{margin: "100px 0 20px"}}>Action RSE NFC travaille en partenariat avec les <span style={{textTransform: "upperCase"}}>experts</span> de la RSE</h3>
          <p><strong>Conseil régional Bourgogne-Franche-Comté</strong> anime le réseau régional RSE qui a pour mission de proposer des services RSE et développement durable aux entreprises. Il organise, en appui avec les partenaires du réseau régional RSE, Les Trophées de la RSE. Il déploie la COP régionale Bourgogne-Franche-Comté, notamment le volet 4 « Mieux produire » et apporte un soutien financier aux démarches RSE des entreprises. <a>https://www.calameo.com/read/003030380d1506eeb6e70</a> et <a>https://www.cop-bfc-versdemain.fr/</a></p>
          <p className='subText' style={{margin: "20px 0", fontStyle: "italic"}}>Dans ce réseau, des experts :</p>
          <div className="grid_4">
            <div className="imgBox">
              <a href="https://aer-bfc.com/">
              <img src='/Assets/thumbnail_image003.jpg'></img>
              </a>
            </div>
            <div className="text">
              <p><strong>Agence Economique Régionale</strong> accompagne le maintien et le développement de l'activité économique et l'emploi sur le territoire, promeut l'attractivité du territoire, soutient et développe l'innovation et la transition écologique.</p>
            </div>

            <div className="imgBox">
              <a href="https://mfq-bfcasso.fr/l-accompagnement-rse">
               <img src='/Assets/thumbnail_image005.jpg'></img>
              </a>
            </div>
            <div className="text">
              <p><strong>Mouvement Français pour la Qualité</strong> accompagne les organisations dans leur démarche qualité, sécurité, environnement et RSE. Avec le soutien de la Région Bourgogne Franche-Comté, le MFQ propose une offre d’accompagnement à la carte pour définir, mettre en place, évaluer et valoriser l’engagement RSE des organisations.</p>
            </div>

            <div className="imgBox">
              <a href="https://www.bpifrance.fr/nous-decouvrir/notre-mission">
               <img src='/Assets/bpiFrance.png'></img>
              </a>
            </div>
            <div className="text">
              <p><strong>BPI France</strong> finance et accompagne les entreprises dans leurs projets de développement, de transition écologique et énergétique, et d’innovation, en appui des politiques publiques conduites par l’Etat et les Régions. <a href="https://www.bpifrance.fr/nous-decouvrir/notre-mission">https://www.bpifrance.fr/nous-decouvrir/notre-mission</a></p>
            </div>

            <h4>PRIAE</h4>
            <div className="text">
              <p>Pôle Ressources de l’Insertion par l’Activité Economique, promeut l’insertion par l’activité économique comme outil de la RSE pour les organisations.</p>
              <a href="https://www.pole-iae-bfc.org/">https://www.pole-iae-bfc.org/</a>
            </div>
            <h4>Le cabinet Koshi</h4>
            <div className="text">
              <p>qui accompagne les entreprises dans leur développement durable et anime la Fresques de la RSE.</p>
              <a href="https://www.koshi.cc/">https://www.koshi.cc/</a>
            </div>
            <h4>Le collectif<br/> Vizcardo Chippeaux</h4>
            <div className="text">
              <p>sur les questions liées à l’économie de la fonctionnalité et de la coopération. </p>
              <a href="https://angievizcardo.com/">https://angievizcardo.com/</a> <p>et</p> <a href="https://www.shareandmove.fr/">https://www.shareandmove.fr/</a>
            </div>
            <h4>OPCAPIAT</h4>
            <div className="text">
              <p>qui mobilise ses adhérents sur des projets de développement de l’emploi local. <a href="https://www.ocapiat.fr/">https://www.ocapiat.fr/</a></p>
            </div>


            <h4>AFNOR</h4>
            <div className="text">
              <p>groupe international qui conçoit des solutions fondées sur les normes volontaires pour les organisations et les personnes.<a href="https://www.afnor.org/le-groupe/qui-sommes-nous/">https://www.afnor.org/le-groupe/qui-sommes-nous/</a></p>
            </div>
         
            <h4>Agence Régional pour la biodiversité</h4>
            <div className="text">
              <p>est aux cotés des entreprises volontaires pour les accompagner dans leur plan d’amélioration de leur impact sur la biodiversité. <a href="https://www.arb-bfc.fr/">https://www.arb-bfc.fr/</a></p>
            </div>
    

          




            <p>La <a target="blank" href="https://www.bourgognefranchecomte.fr/recherche?key=d%C3%A9marches+rse&ctype=&customHiddenField=">Région Bourgogne Franche-Comté</a> accompagne les entreprises dans leurs démarches RSE</p>
            <a target="blank" href="https://action-rse.fr/Assets/Catalogue_RSE_2022_V8.pdf"><img src="https://action-rse.fr/Assets/afficher_catalogue_rse.PNG" alt="" /></a>
          </div>
        </div>

        <div id="normes_et_labels" className="anchor"></div>


        <div className="normesEtLabelRSE">
          <h2>Des normes et des labels RSE</h2>
          <div className="normes">
          <p style={{width: "80%", margin: "0 auto"}}><strong>Une norme RSE</strong> est un cadre de référence qui établit des lignes directrices et des critères pour aider les entreprises à intégrer des préoccupations sociales, environnementales et économiques dans leurs activités et leur stratégie. Les normes RSE encouragent les entreprises à adopter des pratiques durables et éthiques, en tenant compte des impacts de leurs activités sur la société et l'environnement.<br/><br/>

          <strong>Un label RSE</strong> est une certification officielle qui signe l'engagement de l'entreprise dans une démarche de développement durable et de responsabilité sociétale. Les labels sont attribués par des organismes indépendants et reconnus qui peuvent aider les entreprises à mettre en valeur leurs engagements auprès de leurs parties prenantes, comme les clients, les investisseurs ou les employés.<br/><br/>

          <strong>En résumé</strong>, la norme RSE fournit un cadre pour les bonnes pratiques, tandis que le label RSE est une reconnaissance formelle de la conformité à ces normes.</p>
          <div className="anchor" id="les_normes_RSE"></div>
          <h3>Les normes</h3>
          <div className="grid_5">
            <ul>
              <li><strong>La norme « Responsabilité sociétale »</strong> guide les entreprises dans la mise en place de leur politique RSE : <strong>ISO 26000</strong> <a target="blank" href="https://www.iso.org/fr/iso-26000-social-responsibility.html">https://www.iso.org/fr/iso-26000-social-responsibility.html</a></li>
              <li><strong>La norme « Management environnemental »</strong> définit les exigences d’un système de management environnemental (SME) : <strong>ISO 14001</strong> <a target="blank" href="https://www.iso.org/fr/iso-14001-environmental-management.html">https://www.iso.org/fr/iso-14001-environmental-management.html</a></li>
              <li><strong>La norme « Management de l’énergie »</strong> propose des modalités pratiques pour mettre en œuvre un politique de réduction de la consommation d’énergie : <strong>ISO 50001</strong> <a target="blank" href="https://www.iso.org/fr/iso-50001-energy-management.html">https://www.iso.org/fr/iso-50001-energy-management.html</a></li>
              <li><strong>La norme « Système de management de la santé et de la sécurité au travail » ISO 45001</strong> <a target="blank" href="https://www.iso.org/fr/standard/63787.html">https://www.iso.org/fr/standard/63787.html</a></li>
              <li><strong>La norme « système de management de la sécurité de l’information » ISO 27001</strong> <a target="blank" href="https://www.iso.org/fr/standard/27001">https://www.iso.org/fr/standard/27001</a></li>
              <li><strong>La norme « Management de l’énergie » ISO 50001</strong> <a target="blank" href="https://www.iso.org/fr/iso-50001-energy-management.html">https://www.iso.org/fr/iso-50001-energy-management.html</a></li>
            </ul>
            <a target="blank" href="https://www.iso.org/fr/publication/PUB100259.html">
              <img src="https://action-rse.fr/Assets/afficherAfnor.PNG" alt="" />
            </a>
           </div>
          </div>
          <div className="labels">
          <div className="anchor" id="les_labels"></div>

            <h3>Quelques grands types de labels RSE</h3>
            <div className="grid_6">
              <div className="labels_col">
                <h4>Les labels généralistes</h4>
                <div className="label">
                  <a target="blank"href="https://www.labellucie.com/" className='box'>
                    <p>LUCIE</p>
                    <img src="https://action-rse.fr/Assets/logo-certification-lucie-label.png" alt="" />
                  </a>
                </div>
                <div className="label">
                <a target="blank" href="https://certification.afnor.org/developpement-durable-rse/label-engage-rse" className='box'>
                    <p>Engagés RSE</p>
                    <img src="https://action-rse.fr/Assets/rse_initial_contour_Afnor.jpg" alt="" />
                  </a>
                </div>
                <div className="label">
                <a target="blank" href="https://www.responsibility-europe.org/" className='box'>
                    <p>Responsability Europe</p>
                    <img src="https://action-rse.fr/Assets/label_responsibility_europe.png" alt="" />
                  </a>
                </div>
                <p>Mais aussi</p>
                <p>B Corp <a target="blank" href="https://www.bcorporation.fr/">https://www.bcorporation.fr/</a></p>
                <p>EcoVadis <a target="blank" href="https://ecovadis.com/fr/">https://ecovadis.com/fr/</a></p>
                <p>Et cætera</p>
              </div>
              <div className="labels_col">
                <h4>Les labels territoriaux</h4>
                <div className="label">
                <a target="blank" href="https://www.label-emplitude.fr/" className='box'>
                    <div className="emp">
                      <p>Empl’itude</p>
                      <p>Porté, pour le Territoire de Belfort, par la MIFE: <a target="blank" href="https://www.mife90.org/">https://www.mife90.org/</a></p>
                    </div>
                    <img src="https://action-rse.fr/Assets/logo-EMPLITUDE-TdB-transparent.png" alt="" />
                  </a>
                </div>
                <div className="label">
                <a target="blank" href="https://www.excellence.alsace/" className='box'>
                    <p>Alsace Excellence </p>
                    <img src="https://action-rse.fr/Assets/xlogo-label-alsace-excellence.png.pagespeed.ic.q8l-w_RfxW.png" alt="" />
                  </a>
                </div>
                <div className="label">
                <a target="blank" href="https://mairie1.lyon.fr/le-label-lyon-ville-equitable-et-durable" className='box'>
                    <p>Lyon ville équitable et durable</p>
                    <img src="https://action-rse.fr/Assets/logo_label_Lyon_ville_equitable.jpg" alt="" />
                  </a>
                </div>
                <p>Entre autres exemples.</p>
              </div>
              <div className="labels_col">
                <h4>Les labels thématiques</h4>
                <div className="label">
                  <a target="blank" href="https://label-nr.fr/" className='box'>
                    <p>Label Numérique Responsable</p>
                    <img src="https://action-rse.fr/Assets/LNR-blanc-label-nr.webp" alt="" />
                  </a>
                </div>
                <div className="label">
                  <a target="blank" href="https://travail-emploi.gouv.fr/droit-du-travail/egalite-professionnelle-discrimination-et-harcelement/article/le-label-egalite-professionnelle-un-outil-pour-favoriser-l-egalite-femmes" className='box'>
                    <p>Label Egalité professionnelle</p>
                    <img src="https://action-rse.fr/Assets/labels-egalite-afnor.png" alt="" />
                  </a>
                </div>
                <div className="label">
                  <a target="blank" href="https://www.envol-entreprise.fr/" className='box'>
                    <p>Label EnVol</p>
                    <img src="https://action-rse.fr/Assets/logo-EnVol-envol-entreprise.png" alt="" />
                  </a>
                </div>
                <div className="label">
                  <a target="blank" href="https://engagespourlanature.ofb.fr/entreprises" className='box'>
                    <p>Label Entreprise engagée pour la nature</p>
                    <img src="https://action-rse.fr/Assets/TAMPONS_TEN_GENERIQUE.png" alt="" />
                  </a>
                </div>
                <p>Et bien d'autres encore</p>
              </div>
              <div className="labels_col">
                <h4>Les labels sectoriels</h4>
                <div className="label">
                  <a target="blank" href="https://www.snadom.org/label-rse/" className='box'>
                    <p>Label Responsabilité et santé</p>
                    <img src="https://action-rse.fr/Assets/Logo-RS-CMJN.png" alt="" />
                  </a>
                </div>
                <div className="label">
                  <a target="blank" href="https://www.prestadd.fr/" className='box'>
                    <p>Label Prestadd (Synaps)</p>
                    <img src="https://action-rse.fr/Assets/Logo-Prestadd.png" alt="" />
                  </a>
                </div>
                <div className="label">
                  <a target="blank" href="https://moncoiffeursengage.com/" className='box'>
                    <p>Mon Coiffeur s’engage </p>
                    <img src="https://action-rse.fr/Assets/coiffeur-engage.png" alt="" />
                  </a>
                </div>
                <p>etc.</p>
              </div>
            </div>
          </div>
        </div>

        <div id="ateliers_RSE" className="anchor"></div>

        <div className="events">
        <div className="anchor" id="les_ateliers_RSE"></div>
          <h3>L’actualité du pôle Action RSE Nord Franche-Comté</h3>

          <p style={{width: "80%", margin: "50px auto 80px"}}>Les ateliers Action RSE NFC sont destinés aux chefs d’entreprises, aux responsables du développement durable et de la RSE, aux managers, aux élus de CSE… et à tout salarié concerné par les sujets de la RSE. <br/><br/>
          Pour toute information sur les ateliers de sensibilisation à la RSE, <Link to="/#contact">contactez-nous</Link>
          </p>

          <div className="btn"><Link to="/archives">Voir les événements passés</Link></div>
        </div>

        <RSECalendar />

        <div id="contact" className="contact">
          <h3>Pour nous contacter</h3>

          <div className="card">
            <div className="logo">
              <img src="https://action-rse.fr/Assets/logo-MIFE90.jpg" alt="" />
            </div>
            <a target="blank" href="mailto:claire.privat@mife90.org">claire.privat@mife90.org</a>
            <a target="blank" href="tel:0634183409">06 34 18 34 09</a>
          </div>
          <p>ou</p>
          <div className="card">
            <div className="logo">
               <img src="https://action-rse.fr/Assets/IDEIS_logo-01-removebg-preview.png" alt="" />
            </div>
            <a target="blank" href="mailto:maud.petit@ideis-asso.fr">maud.petit@ideis-asso.fr</a>
            <a target="blank" href="tel:0628464301">06 28 46 43 01</a>
          </div>
        </div>
      </div>
     </div>
    </>
  )
}
