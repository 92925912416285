import React, {useEffect, useState, useRef} from 'react';
import { useForm } from 'react-hook-form';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import {v4 as uuidv4} from 'uuid'
import { doc, setDoc, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import {db} from '../firebase-config'
import DatePicker from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { ImBin } from "react-icons/im";
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';



export default function AddToAgenda({preloadedData, setModal}) {
    
    registerLocale('fr', fr);
    setDefaultLocale('fr');

           
    const id = preloadedData ? preloadedData.id : uuidv4()

    console.log(id);
    console.log(preloadedData);

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onTouched',
      });

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [error, setError] = useState('');

    const handleDelete = async () => {
        const ref = doc(db, "calendrier", id );
        // eslint-disable-next-line no-restricted-globals
        window.confirm("Etes-vous sûr ? \n Cette action est irréversible")

        if(window.confirm("Etes-vous sûr ? \n Cette action est irréversible") == true) {
            let docRef = await deleteDoc(ref)
            setModal(false)
            window.location.reload()
        }
    }

    const formRef = useRef()

    const handleStartHourChange = (date) => {
            if((endDate && date > endDate)) {
                alert("Veuillez choisir une date antérieure à la date de début de l'événément")
            } else {
                setStartDate(date);
            }

    };


    useEffect(() => {
        reset(preloadedData)
    }, [preloadedData])

    const handleEndHourChange = (date) => {
        if((startDate && date < startDate)) {
            alert("Veuillez choisir une date ultérieure à la date de début de l'événément")
        } else {
            setEndDate(date);
        }

    };

    const onSubmit = async data => {

        let eventInfo = {}
       
        
        if(startDate && endDate) {
            eventInfo = {
                ID: id,
                title: data.title,
                start: startDate.toLocaleString(),
                end: endDate.toLocaleString(),
                descriptif: data.descriptif,
                collaboration: data.collaboration ? data.collaboration : " "
        }

        } else if(!startDate) {
            setError("Veuillez choisir une date de début")
        } else if(!endDate) {
            setError("Veuillez choisir une date de début")
        }

        console.log(data + ' \n ' + startDate + ' \n ' + endDate);
        

        try {
            const ref = doc(db, "calendrier", id );
            if(!preloadedData) {
                let docRef = await setDoc(ref, eventInfo)
            } else {
                let docRef = await updateDoc(ref, eventInfo)
            }
            formRef.current.reset("")


        } catch (err) {
            console.log("Erreur" + err);
            
        }
    }
  

    return (
        
        <div className="addToAgenda">

            <h2>{preloadedData ? "Modifer l'événement" : "Ajouter un événement à l'agenda"}</h2>
            
            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                {error &&
                <p className='validationText'>{error}</p>
                }
            <div>
                <input
                    id="title"
                    name="title"
                    placeholder="Titre de l'événement"
                    {...register('title', { required: 'Le titre de l\'événement est requis' })}
                />
                {errors.title && <p>{errors.title.message}</p>}
            </div>

            <div className='pickers'>
                <div className="dateDeDebut">
                    <label htmlFor="email">Début de l'événement :</label>
                    <DatePicker
                    placeholderText={preloadedData && preloadedData.start.toLocaleString().slice(0, 16)}
                    name="start"
                    selected={startDate}
                    onChange={handleStartHourChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Heure"
                    locale="fr" 
                    />
                </div>
                <div className="dateDeFin">
                    <label htmlFor="email">Fin de l'événement :</label>
                    <DatePicker
                    name="end"
                    placeholderText={preloadedData && preloadedData.end.toLocaleString().slice(0, 16)}
                    selected={endDate}
                    onChange={handleEndHourChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Heure"
                    locale="fr" 
                    />
                </div>
            </div>

            <textarea placeholder="Descriptif" rows="5" {...register('descriptif', { required: 'La description de l\'événement est requis' })} name="descriptif" id=""></textarea>
            <textarea placeholder="Collaborateurs" rows="4" {...register('collaboration')} name="collaboration" id=""></textarea>
            
            <button type="submit">{preloadedData ? "Modifier" : "Soumettre"}</button>
            {preloadedData && 
             <button type="button" onClick={handleDelete}><ImBin /> Supprimer l'événement</button>
            }
        </form>
        </div>
    
    )

}