import React from 'react'

export default function Modal({fullImgSrc, setModal, position}) {
  return (
    <div style={{top: (position - 150) + "px"}} className="modal">
      <div onClick={() => setModal(false)} className="overlay"></div>
      <div className="modalBox">
        <img src={fullImgSrc} alt="" />
      </div>
    </div>
  )
}
