import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase-config';
import moment from 'moment';
import EventModal from './EventModal'
import 'moment/locale/fr'; 
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { logDOM } from '@testing-library/react';

export default function RSECalendar() {

    const [events, setEvents] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState({});
    const [scrollYPosition, setScrollYPosition] = React.useState(0);

    const handleScroll = () => {
      const newScrollYPosition = window.pageYOffset;
      setScrollYPosition(newScrollYPosition);
     };
  
     useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

    moment.locale('fr');
    const localizer = momentLocalizer(moment);

    const messages = {
        allDay: 'Toute la journée',
        previous: 'Précédent',
        next: 'Suivant',
        today: "Aujourd'hui",
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour',
        agenda: 'Agenda',
        date: 'Date',
        time: 'Heure',
        event: 'Événement',
    };

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
        setModalOpen(true);
        console.log(event);
    };


    console.log(modalOpen);
    console.log(selectedEvent);
    

    const loadEvents = async () => {
        const ref = collection(db, 'calendrier');
        const querysnapshot = await getDocs(ref);
        
        const eventsArray = [];
        querysnapshot.forEach((doc) => {
            const data = doc.data();
            const [startDatePart, startTimePart] = data.start.split(" ");
            const [endDatePart, endTimePart] = data.end.split(" ");
            
            const [startDay, startMonth, startYear] = startDatePart.split("/").map(Number);
            const [endDay, endMonth, endYear] = endDatePart.split("/").map(Number);
            const [startHours, startMinutes] = startTimePart.split(":").map(Number);
            const [endHours, endMinutes] = endTimePart.split(":").map(Number);

            const startDateObject = new Date(startYear, startMonth - 1, startDay, startHours, startMinutes);
            const endDateObject = new Date(endYear, endMonth - 1, endDay, endHours, endMinutes);

            eventsArray.push({
                id: data.ID,
                title: data.title,
                start: startDateObject,
                end: endDateObject,
                descriptif: data.descriptif,
                collaboration: data.collaboration,
            });
        });
        setEvents(eventsArray);
    };

    useEffect(() => {
        setEvents([]); 
        loadEvents();
    }, []);

    return (
        <>
        {modalOpen === true && 
            <EventModal setModalOpen={setModalOpen} data={selectedEvent} position={scrollYPosition}/>   
        }
        <div className='calendar' style={{ margin: '50px' }}>
            {events.length > 0 &&
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    messages={messages}
                    onSelectEvent={handleSelectEvent}
                />
            }
        </div>
        </>
    );
}