import React, {useEffect, useState, useContext} from 'react'
import Linkify from './Linkify'
import { RxCross2 } from "react-icons/rx";
import { UserContext } from '../context/userContext';
import { CiEdit } from "react-icons/ci";
import AddToAgenda from '../pages/AddToCalendar';

export default function EventModal({data, setModalOpen, position}) {

  const [timeStamp, setTimeStamp] = useState("")

  const [currentUserMail, setCurrentUserMail] = useState("")
  const [editEvent, setEditEvent] = useState(false)

  const { currentUser, userData } = useContext(UserContext)

  const replacements = {
    '/': ' / ',
    '2024': '2024  |',
  };

  console.log(currentUser)

  const replaceAll = (text, replacements) => {
    let result = text;

    Object.entries(replacements).forEach(([key, value]) => {
      result = result.replaceAll(key, value);
    });

    return result;
  };

  const updatedText = replaceAll(timeStamp, replacements);

  
  useEffect(() =>{
    if(!(currentUser === null || currentUser === undefined)) {
      setCurrentUserMail(currentUser.auth.currentUser.email)
    }
  }, [currentUser])


  console.log(userData.email === currentUserMail);
  
  const formatTime = async () => {

    if(data.start.toLocaleString().slice(0,10) === data.end.toLocaleString().slice(0,10)) {
      setTimeStamp(data.start.toLocaleString().replace(":", "h").slice(0, 16) + " - " + data.end.toLocaleString().replace(":", "h").slice(11, 16))
    } else {
      setTimeStamp(data.start.toLocaleString().replace(":", "h").slice(0, 16) + " - " + data.end.toLocaleString().replace(":", "h").slice(0, 16))
    }
  }
 

  useEffect(() => {
    formatTime()
  }, [])
  
  
  return (
    <div style={{ top: (position - 150) + "px" }} className="eventModal">
      <div onClick={() => setModalOpen(false)} className="overlay"></div>
      <div className="modalBox">
        <div className="close" onClick={() => setModalOpen(false)}>
          <RxCross2 />
        </div>
        {data && (
          <>
            {editEvent ? (
              <AddToAgenda preloadedData={data} setModal={setModalOpen}/>
            ) : (
              <>
                <div className="event">
                  <h2>{data.title}</h2>
                  <h4>{updatedText}</h4>
                  <div className="description">
                    <Linkify>{data.descriptif}</Linkify>
                  </div>
                  <p style={{ fontWeight: 700, padding: "10px 5px" }}>{data.collaboration}</p>
                </div>
  
                {currentUser && userData.email === currentUserMail && (
                  <button onClick={() => setEditEvent(true)} className="editEventBtn">
                    <CiEdit /> <span>Modifier l'événement</span>
                  </button>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}