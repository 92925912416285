import React, { useEffect, useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import { TiArrowBack } from "react-icons/ti";
import Modal from '../components/Modal'

export default function Archives() {

  const [modal, setModal] = useState()
  const [fullImgSrc, setFullImgSrc] = useState()
  const [scrollYPosition, setScrollYPosition] = React.useState(0);

  const handleScroll = () => {
    const newScrollYPosition = window.pageYOffset;
    setScrollYPosition(newScrollYPosition);
   };

   useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);


  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  const handleFullImg = async (e) => {
    setModal(true)
    setFullImgSrc(e.target.src)
  }


  return (
   <>
    {modal && 
        <Modal setModal={setModal} fullImgSrc={fullImgSrc} position={scrollYPosition}/>   
    }

    <div className='archives'>
      <div className="content">
      <div className="btn"><Link to="/"><TiArrowBack /> Retour</Link></div>

        <h1>Événements passés du pôle action RSE Nord Franche-Comté</h1>
        <div className="elements">
          <h3>En 2024</h3>
          <div className="grid_7">
            <div className="row_toLeft">
              <div className="imgs">
                <img onClick={(e) => handleFullImg(e)} src="https://action-rse.fr/Assets/2024_03_10_Invitation_atelier_EFC.jpg" alt="" />
                <img onClick={(e) => handleFullImg(e)} src="https://action-rse.fr/Assets/2024_10_03_Programme_atelier_EFC.jpg" alt="" />
              </div>
              <p><strong>Économie de la fonctionnalité et de la coopération</strong> animé par <a target="blank" href="https://angievizcardo.com/">Angie VIZCARDO</a> et <a target="blank" href="https://www.shareandmove.fr/">Grégory CHIPEAUX</a> – dans l’entreprise <a target="blank" href="https://www.cristel.com/">CRISTEL</a> à Fresche-Le-Châtel <strong>– jeudi 3 octobre 2024</strong> <br/><br/></p>
            
            </div>
        
          </div>
        <div className="row_toLeft">
              <p><strong>Escape game QVCT</strong> animé par l’Aract bfc à IDEIS 23 rue de Gascogne à Grand-Charmont. 30 mai 2024</p>
              <img onClick={(e) => handleFullImg(e)} src="https://mife90.org/RSE/Assets/2024_05_30_invitation_escape_game_QVCT_page-0001.jpg" alt="" />
            </div>
            <div className="row_toRight">
              <img onClick={(e) => handleFullImg(e)} src="https://mife90.org/RSE/Assets/SeriousGame.jpg" alt="" />
              <p><strong>Serious game « les défis du travail liés à la transition énergétique »</strong> – animé par l’Aract BFC à la MIFE de Belfort – 5 mars 2024</p>
            </div>
          <h3>En 2023</h3>
          <div className="row_toLeft">
            <div className="left">
              <img onClick={(e) => handleFullImg(e)} src="https://mife90.org/RSE/Assets/800px-Logo_fresque_de_la_RSE.jpg" alt="" />
              <p><strong>La Fresque de la RSE</strong> animée par Chrystelle CHATELAIN, consultante cabinet <a href="https://www.koshi.cc/">Koshi</a> en novembre — décembre 2023 : <strong>Grand-Charmont, Héricourt, Belfort</strong></p>
            </div>
            <div className="imgs">
              <img onClick={(e) => handleFullImg(e)} src="https://mife90.org/RSE/Assets/fresqueRse2-edited.jpg" alt="" />
              <img onClick={(e) => handleFullImg(e)} src="https://mife90.org/RSE/Assets/fresqueRse1.jpg" alt="" />
            </div>
          </div>
          <div className="row_toRight">
            <img style={{maxWidth: "150px"}} onClick={(e) => handleFullImg(e)} src="https://mife90.org/RSE/Assets/channels4_profile.jpg" alt="" />
            <p><strong>Petit déjeuner « Achats responsables »</strong> animé par le PRIAE, à la brasserie d’insertion « l’Expo » à Belfort le 13 octobre 2023</p>
          </div>
          <p>Temps de Partage d’exemples d’achat socialement responsable avec visite de FRIPVIE et présentation des clauses d’insertion dans le cadre de la charte RST sur le Pays de Montbéliard – 26 mai 2023 </p>
          <div className="row_toLeft">
            <p><strong>Mini conférence de sensibilisation à la RSE</strong> à Belfort lors du forum AGORAJOBS du 13 mai 2023 -, avec les témoignages de deux entreprises labellisées Empl’itude : Toitures Morel pour le volet social et Albizzati Père et Fils pour le volet environnemental.</p>
            <img onClick={(e) => handleFullImg(e)} src="https://mife90.org/RSE/Assets/20230513_102418-1024x793.jpg" alt="" />
          </div>
          <p><strong>Visite de METALHOM et échanges de bonnes pratiques RSE</strong> dans le cadre delà charte RST sur le Pays de Montbéliard – 22 février 2023</p>
         
        </div>
      </div>
     </div>
    </>
  )
}
